import React from 'react'
import { Link } from "react-router-dom";
import { PlayCircle } from '@mui/icons-material'
import { useState } from 'react';

import VideoPlayerModal from '../../components/modals/VideoPlayerModal';
const CourseCertifications = ({categories,access,test_type}) => {
  const [modal,setmodal] = useState(false)
  
  const HandleClose = ()=>{
  setmodal(false)}
  return (
    <div className='min-vh-100'>

      <div className="w-100  course-certifications">
      <div className="container ">
   <VideoPlayerModal visibility={modal} HandleClose={HandleClose} video={"https://skillsuprise.com/data/videos/course-certification.mp4"}/>
   <div className="d-flex text-light flex-wrap justify-content-between pt-5">

     <div className="col-lg-7">
     <h1 className='sm-25'>Master Skills with Industry-Ready Certification</h1>
<h6>Take Chapterwise Tests and Earn Certificates to Unlock Your Potential and Showcase Your Expertise</h6>


<button onClick={()=>setmodal(true)} className='btn btn-warning mt-3'><PlayCircle/> Watch Demo</button>      </div>

<div className="col-lg-3 sm-mt-20">
<img src="https://skillsuprise.com/resources/images/cert-image.png" className='w-100'></img>
</div>

   </div>
<p></p>


   </div>
      </div>
        
      

     <div className="container pb-5">


     <div className="d-flex flex-wrap">

{categories?
<>
{categories.map((item)=>(
  <>
  
  <div className="col-lg-4 col-12 col-md-4 p-3">

<div className="shadow rounded position-relative">
<img src={item.demo_certificate} className='w-100 bg-dark '></img>

<div className="position-absolute certification-screen left-0 top-0 d-flex w-100 h-100 justify-content-center align-items-center">



<Link to={"/exam/course_certification/"+item.course_url+"/"+item.test_code} className="btn btn-primary rounded rounded-pill ">Start Exam</Link>


</div>

</div>
<p className='text-center my-1'>Exam Code : {item.test_code}</p>
</div>

  </>))}
</>:
<>

</>}


        </div>
     </div>
      </div>
  )
}

export default CourseCertifications