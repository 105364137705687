import React, {useEffect, useRef } from 'react'
import divider from "../../assets/bg/divider.png";

const VideoReviews = () => {

  const mainVideoRef = useRef(null);
  const videoRefs = useRef([]);
  
  useEffect(() => {
    const handlePlayPause = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          mainVideoRef.current.play();
        } else {
          mainVideoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.5, // Adjust this value as needed
    });

    if (mainVideoRef.current) {
      observer.observe(mainVideoRef.current);
    }

    return () => {
      if (mainVideoRef.current) {
        observer.unobserve(mainVideoRef.current);
      }
    };
  }, []);

  const handleMouseEnter = (index) => {
    videoRefs.current[index].play();
    videoRefs.current[index].controls = true;
    pauseOtherVideos(index);
  };

  const handleMouseLeave = (index) => {
    videoRefs.current[index].pause();
    videoRefs.current[index].controls = false;
  };

  const handleVideoClick = (index) => {
    if (!videoRefs.current[index].paused) {
      videoRefs.current[index].pause();
    } else {
      videoRefs.current[index].play();
      pauseOtherVideos(index);
    }
  };

  const pauseOtherVideos = (currentIndex) => {
    videoRefs.current.forEach((video, index) => {
      if (index !== currentIndex) {
        video.pause();
        video.controls = false;
      }
    });
  };
  return (
    <>

<section>
        {/* <div className="container mt-5 mb-5 pt-5 text-center">
          <h2>Students Love, Trust & Respect Us</h2>
          <p className="col-lg-6 col-md-6 d-none d-md-block col-sm-9 col-12 mx-auto sm-mb-20">
            We offer the most interactive and competitive environment with team
            challenges, competitions, and many more to make learning a
            loving experience
          </p>

          <div className="divider">
            <img src={divider} alt="divider" />
          </div>
        </div> */}

        <div className="container py-5 d-flex flex-wrap justify-content-evenly animate">
          <div className="col-lg-3">
            <video
              ref={mainVideoRef}
              id="main-video"
              className="bg-dark border rounded w-100 h-100"
              src="https://skillsuprise.com/data/videos/home-review-main.mp4"
              poster="https://skillsuprise.com/data/videos/home-review-main.png"
              muted // Optional: mutes the video so it can autoplay without user interaction
            ></video>
          </div>

          <div className="col-lg-7 flex-wrap d-flex">
            {[
              'home-review1.mp4',
              'home-review2.mp4',
              'home-review3.mp4',
              'home-review4.mp4'
            ].map((video, index) => (
              <div key={index} className="col-lg-6 col-md-6 col-6 p-2">
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  className='w-100 rounded border bg-dark'
                  src={`https://skillsuprise.com/data/videos/${video}`}
                  poster={`https://skillsuprise.com/data/videos/${video.replace('.mp4', '.png')}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  onClick={() => handleVideoClick(index)}
                ></video>
              </div>
            ))}
          </div>
        </div>
      </section>

    </>
  )
}

export default VideoReviews