import React, { useEffect, useState } from "react";
import axios from "axios";
import ENDPOINT from "../../data/ENDPOINT";
import divider from "../../assets/bg/divider.png";
import Skeleton from "@mui/material/Skeleton";
import { Avatar } from "@material-ui/core";
import { Helmet } from "react-helmet";
import SecurityHeaders from "../../data/SecurityHeaders";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import SetPage from "../../data/SetPage";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Button from "@mui/material/Button";
import styled from "styled-components";
import img5 from "../../assets/images/students-placement images/images/img7.png";
import img6 from "../../assets/images/students-placement images/images/img9.png";
import img7 from "../../assets/images/students-placement images/images/img10.png";
import img8 from "../../assets/images/students-placement images/images/img8.png";
import img9 from "../../assets/images/students-placement images/images/img6.png";
import img10 from "../../assets/images/students-placement images/images/img11.png";
import img11 from "../../assets/images/students-placement images/images/img5.png";
import bg2 from "../../assets/images/students-placement images/images/img12.svg";
import Localbase from "localbase";
import HomeDesktopReviews from "../home/HomeDesktopReviews";
import VideoReviews from "./VideoReviews";

const StudentReviews = () => {
  const [reviews, setReviews] = useState(null);
  const db = new Localbase("db");

  useEffect(() => {
    window.scrollTo(0, 0);
    SetPage("Reviews");
    const getReviews = async () => {
      try {
        const data = new FormData();
        data.append("limit", 30);

        const res = await axios.post(
          ENDPOINT + "get-reviews.php",
          data,
          SecurityHeaders,
        );
        if (res) {
          if (res.data.status === "success") {
            setReviews(res.data.data);
            db.collection("paths").add({ reviews: res.data.data }, "reviews");
          }
        } else {
          console.log("Fetching Reviews Error");
        }
      } catch (error) {
        console.log("get reviews Error ", error);
      }
    };
    getReviews();
  }, []);

  const Getofflinedata = () => {
    db.collection("paths")
      .doc("reviews")
      .get()
      .then((data) => {
        setReviews(data.reviews);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    Getofflinedata();
  }, [window.location]);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const emptyStars = 5 - fullStars;
    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <StarIcon key={index} style={{ color: "gold" }} />
          ))}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <StarBorderIcon key={index} style={{ color: "gold" }} />
          ))}
      </>
    );
  };

  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };
  const value = 3.5;

  return (
    <StyledStudentsReviews>
      <>
        <Helmet>
          <title>Skills Uprise - Reviews | Students Feedbacks</title>
          <meta
            name="description"
            content="Skills uprise is the best place for online training and offline trainings. The trainer is so friendly and teaches everything so easily understandable way. Skills Uprise reviews"
          />
          <meta name="twitter:card" content="" />
          <meta name="twitter:site" content="@skillsuprise" />
          <meta name="twitter:creator" content="@skillsuprise" />
          <meta name="twitter:title" content="" />
          <meta
            name="twitter:description"
            content="Skills uprise is the best place for online training and offline trainings. The trainer is so friendly and teaches everything so easily understandable way. Skills Uprise reviews"
          />
          <meta name="twitter:image" content="" />
          <meta property="og:title" content="" />
          <meta
            property="og:description"
            content="Skills uprise is the best place for online training and offline trainings. The trainer is so friendly and teaches everything so easily understandable way. Skills Uprise reviews"
          />
          <meta property="og:image" content="" />
          <meta property="og:url" content="" />
          <meta property="og:site_name" content="Skills Uprise" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
        </Helmet>

        {/* <div className="container-fluid pt-5 pt-md-0 bg-dark review-crumb">
        <div className="flex-wrap sm-p-15 tab-reverse pb-0 pt-5">
          <div className="col-lg-5 position-relative sm-pt-0 pt-5">
            <img
              src={"https://skillsuprise.com/resources/images/girl-smile.png"}
              className="w-75 position-absolute bottom-0 left-0"
              alt="Smiling girl"
            />
          </div>
          <div className="col-lg-7 col-sm-12 pt-5 sm-p-15">
            <div className="col-md-7 mx-md-auto">
              <h6 className="light-grey sm-text-center">Respected by professionals &</h6>
              <h1 className="sm-22 sm-text-center text-light">Loved By Everyone</h1>
              <p className="light-grey sm-text-center">
                This is what most of our students say about our trainings
              </p>
            </div>
            <hr className="text-light col-lg-8 col-sm-12" />
            <div className="col-lg-8 col-md-7 mx-md-auto col-sm-12 col-12 pb-5">
              <video
                controls
                controlsList="play pause volume seeking nodownload foobar"
                loop
                muted
                poster="https://skillsuprise.com/data/images/poster2.png"
                autoPlay
                id="prime-video1"
                className="w-100 bg-white br-5"
                preload="true"
                src="https://skillsuprise.com/data/videos/placement-training.mp4"
              ></video>
            </div>
          </div>
        </div>
      </div> */}

        <div className="container-fluid background-image2">
          <div className="py-5">
            <h3 className="text-white text-center fs1 col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
              Find the best online course for grow your{" "}
              <span className="bg text-dark fs2 rounded-4 px-2 py-0">
                skills
              </span>{" "}
              & define your{" "}
              <span className="mt-5 bg1 fs2 text-dark rounded-4 px-2 py-0">
                future
              </span>{" "}
            </h3>

            <div className="d-flex flex-wrap justify-content-center p-2 mt-3">
              <div className="d-lg-block d-md-block d-sm-none d-none col-2 mt30 p-1">
                <img className="w-100" src={img5}></img>
              </div>

              <div className="d-lg-block d-md-block d-sm-none d-none col-2">
                <div className="p-1">
                  <img className="w-100" src={img6}></img>
                </div>
                <div className="p-1">
                  <img className="w-100" src={img7}></img>
                </div>
              </div>

              <div className="col-2 d-lg-block d-md-block d-sm-none d-none px-1">
                <img className="w-100" src={img8}></img>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                <div className="px-1">
                  <img className="w-100" src={img9}></img>
                </div>
                <div className="">
                  <img className="w-100" src={img10}></img>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-6 col-6 mt30 p-1">
                <img className="w-100" src={img11}></img>
              </div>
            </div>
          </div>
        </div>

      

        

        <div className="container pt-5 mt-5 d-lg-block d-md-block d-sm-none d-none">
          <h2 className="sm-25 text-center text-primary">
            Here's What
            <br /> Our Students Say About Us!
          </h2>
          <div className="divider mb-2">
            <img src={divider} alt="divider" />
          </div>
        </div>

        <VideoReviews/>
        <div className="container">
          <div className="  m-0 m-md-0 border-0 border-md-1 pt-4 p-md-4">
            <div className="d-flex justify-content-between">
              <div>
                <h2 className="ms-0 text-secondary">STUDENTS REVIEW</h2>
              </div>
              <Button
                variant="contained"
                color="primary"
                className="h-25 d-none"
                startIcon={<BorderColorIcon />}
              >
                Write a review
              </Button>
            </div>
            <div className="">
              {reviews
                ? reviews.map((review, index) => (
                    <div key={index}>
                      <div className="p-2 shadow-none border shadow-sm col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                        <div className="d-flex">
                          <Avatar
                            src={review.profile_pic}
                            sx={{ width: 60, height: 60 }}
                          />
                          <div className="ms-2">
                            <h5>{review.name}</h5>
                            <div>
                              <Box
                                sx={{
                                  width: 200,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Rating
                                  name="text-feedback"
                                  value={review.rating}
                                  readOnly
                                  precision={0.5}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Box sx={{ ml: 2 }}>{labels[value]}</Box>
                              </Box>
                            </div>
                          </div>
                        </div>
                        <p className="mt-2 ps-3">{review.review}</p>

                        {/* <div className='d-flex ps-3'>
            <ThumbUpOffAltIcon sx={{ width: 25, height: 25 }} />
            <h5>(0)</h5>
          </div>

          <hr></hr> */}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </>
    </StyledStudentsReviews>
  );
};

export default StudentReviews;
const StyledStudentsReviews = styled.div`
  .background-image2 {
    background-image: url(${bg2});
    background-size: cover;
    background-position: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .fs1 {
    font-size: 50px;
    line-height: 50px;
    font-family: serif-font;
  }
  .fs2 {
    font-size: 40px;
  }

  .colr {
    color: #1034a6;
  }

  .btncl {
    background: #1034a6;
  }

  .mt30 {
    margin-top: 75px;
  }
  .bg {
    background-color: #fdff72;
  }

  .bg1 {
    background-color: #5ced73;
  }
`;

const Design = () => {
  return (
    <>
      {/* <div className=' col-lg-11 col-md-11 col-sm-12 col-12 m-0 m-md-5 border p-0 p-md-4 '>

<div className='d-flex flex-wrap justify-content-between'>
  <div>
    <h2 className='ms-5 text-secondary'>STUDENTS REVIEW</h2>
    <h6 className='ms-5 text-secondary'>1 DAY AGO</h6>
  </div>
  <div className='d-lg-block d-md-block d-sm-none d-none'>
    <Button variant="contained" color="primary" className="h-50 "
      startIcon={<BorderColorIcon />}>
      Write a review
    </Button>
  </div>

</div>
<div className=''>
  <div className='p-2'>
    {data.map((item, index) => (
  
    ))}
  </div>
</div>
</div> */}
    </>
  );
};
